export default class PracaFuncionario {
  constructor(
    id = "",
    idPraca = "",
    idFuncionario = "",
    funcionario = "",
    dataInicioVinculo = "",
    dataFinalVinculo = "",
    dataInicioString = "",
    dataFinalString = ""
  ) {
    this.Id = id
    this.IdPraca = idPraca
    this.IdFuncionario = idFuncionario
    this.Funcionario = funcionario
    this.DataInicioVinculo = dataInicioVinculo
    this.DataFinalVinculo = dataFinalVinculo
    this.DataInicioString = dataInicioString
    this.DataFinalString = dataFinalString
  }
}
