import PracaFuncionario from "../praca/PracaFuncionarios.js"
export default class Praca {
  constructor(
    id = "",
    nomePraca = "",
    ativo = "",
    codFuncionario = "",
    nomeFuncionaio = "",
    dataVinculo = "",
    dataInicioString = "",
    pracaFuncionarios = new Array(new PracaFuncionario())
  ) {
    this.Id = id
    this.NomePraca = nomePraca
    this.Ativo = ativo
    this.CodFuncionario = codFuncionario
    this.DataVinculo = dataVinculo
    this.DataInicioString = dataInicioString
    this.NomeFuncionario = nomeFuncionaio
    this.PracaFuncionariosDto = pracaFuncionarios
  }
}
