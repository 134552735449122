<template>
  <v-form>
    <v-text-field
      class="mr-2"
      label="Praça"
      name="praca"
      placeholder="Praça"
      v-model="praca.nomePraca"
      :readonly="true"
    ></v-text-field>
    <v-layout row wrap justify-center>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Ativo"
            name="ativo"
            placeholder="Ativo"
            v-model="praca.ativo"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data do vínculo"
            name="dataVinculo"
            placeholder="Data do vínculo"
            v-model="praca.dataInicioString"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
    </v-layout>
    <v-text-field
      class="mr-2"
      label="Funcionário"
      name="funcionário"
      placeholder="Funcionário"
      v-model="praca.nomeFuncionario"
      :readonly="true"
    ></v-text-field>
    <v-data-table :headers="headers" :items="praca.pracaFuncionariosDto" hide-actions class="elevation-0">
      <template v-slot:items="props">
        <td>{{ props.item.id }}</td>
        <td>{{ props.item.funcionario }}</td>
        <td>{{ props.item.dataInicioString }}</td>
        <td>{{ props.item.dataFinalString }}</td>
      </template>
    </v-data-table>
  </v-form>
</template>
<script>
import Praca from "../../domain/praca/Praca.js"
import { ServicoPraca } from "../../servicos/servicoPraca"
const servicoPraca = new ServicoPraca()
export default {
  data: () => ({
    id: "",
    praca: new Praca(),
    headers: [
      {
        text: "Código",
        align: "left",
        sortable: false,
        value: "id"
      },
      { text: "Funcionário", sortable: false, align: "left", value: "funcionario" },
      { text: "Início do vínculo", sortable: false, align: "left", value: "dataInicioString" },
      { text: "Fim do vínculo", sortable: false, value: "dataFinalString", align: "left" }
    ]
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoPraca.buscarPorId(this.id).then(res => {
        this.praca = res.data
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
